import { Spin } from 'antd'
import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Slide } from './Slide'
import { VideoControls } from '../Controls/VideoControls'
import { InteractivePlayerView } from '../InteractivePlayerView'
import PlayPauseOverlay from '../UI/PlayPauseOverlay/PlayPauseOverlay'

import { GAP_FOR_NAVIGATION_EVENTS } from '../../utils'
import { EVENT_TYPE } from '../../utils/video-helpers/types'
import { ScheduledPlayerContextProvider } from '../../contexts'
import { useAnalyticContext, useClientContext, usePlayerContext, useUserContext } from '../../hooks'
import { createRAFCallback } from '../../utils/interactivity-helpers'

/*
  Stands for:
    - wraps video handlers with extra interactivity logic and pass it via context
    - sets current slide
    - sets actions into the schedule
    - signals to the upper component about readiness
*/

export const InteractiveController = (props) => {
  const { children, isVideoScheduleReady, setIsActionsScheduled, videoSchedule, isActionsScheduled, videoElement } =
    props
  const [currentSlideId, setCurrentSlideId] = useState(null)
  const [slideEvent, setSlideEvent] = useState(null)
  const currentSlideDataRef = useRef(null)

  const { analyticCollector } = useAnalyticContext()
  const {
    rewindOnTimeHandler,
    isPaused,
    isVideoReady,
    forceChangedTime,
    playDisabledHandler,
    playerBounding,
    controlSetToHoverHandler,
  } = usePlayerContext()
  const { isDebug } = useClientContext()
  const { email, name } = useUserContext()

  const { slidesMetaHashMap = {} } = videoSchedule ?? {}
  const currentSlideMeta = slidesMetaHashMap[currentSlideId] ?? {}

  const { isNavigationAllowed } = videoSchedule ?? {}

  const rewindOnSlideHandler = useCallback(
    (slideId, shouldPlay = true) => {
      if (!videoElement) return
      if (!videoSchedule) return
      if (isDebug) console.log('RUN REWIND ON SLIDE HANDLER')
      const slideMeta = videoSchedule.slidesMetaHashMap[slideId]
      if (slideMeta === undefined) return
      // TODO look more comprehensive on time. WA to not show prev slide content
      const { startTime } = slideMeta
      const time = startTime + (startTime ? GAP_FOR_NAVIGATION_EVENTS : 0)
      rewindOnTimeHandler(time, shouldPlay)
    },
    [videoElement, videoSchedule],
  )

  const rewindToPrevSlideHandler = useCallback(
    (time, shouldPlay) => {
      if (!videoElement) return
      if (!videoSchedule) return
      // Adding some additional time (GAP_FOR_NAVIGATION_EVENTS) to not find slide if it is already in the beginning,
      // so we can safely navigate to prev slide in the next step
      let foundSlide = videoSchedule.slidesSchedule.find(
        (slide) => slide.startTime < time - GAP_FOR_NAVIGATION_EVENTS && time <= slide.endTime,
      )
      if (foundSlide) rewindOnSlideHandler(foundSlide.slideId, shouldPlay)
      foundSlide = videoSchedule.slidesSchedule.find(
        (slide) => slide.startTime + GAP_FOR_NAVIGATION_EVENTS * 2 > time && time < slide.endTime,
      )
      const slideMeta = videoSchedule.slidesMetaHashMap[foundSlide?.slideId]
      if (slideMeta) rewindOnSlideHandler(slideMeta.prevSlideId, shouldPlay)
    },
    [videoElement, videoSchedule],
  )

  const rewindToNextSlideHandler = useCallback(
    (time, shouldPlay) => {
      if (!videoElement) return
      if (!videoSchedule) return
      const foundSlide = videoSchedule.slidesSchedule.find(
        (slide) => slide.startTime <= time + GAP_FOR_NAVIGATION_EVENTS && time <= slide.endTime,
      )
      const slideMeta = videoSchedule.slidesMetaHashMap[foundSlide?.slideId]
      if (slideMeta?.nextSlideId) {
        rewindOnSlideHandler(slideMeta.nextSlideId, shouldPlay)
      } else {
        rewindOnTimeHandler(foundSlide?.endTime, shouldPlay)
      }
    },
    [videoElement, videoSchedule],
  )

  const setSlideData = (slideId, eventData) => {
    setCurrentSlideId(slideId)
    setSlideEvent(eventData)
    currentSlideDataRef.current = { slideId, eventData }
  }

  useEffect(() => {
    // register actions in the schedule
    if (!videoSchedule) return
    if (!videoSchedule.hasVideoInteractivity) {
      setIsActionsScheduled(true)
      return
    }
    videoSchedule.timeKeysQueue.forEach((timeKey) => {
      const eventsOnThisTime = videoSchedule.eventsMetaGroupedHashMap[timeKey]
      eventsOnThisTime.forEach((eventData) => {
        const { eventType, slideId, nextNavigationSlide } = eventData
        switch (eventType) {
          case EVENT_TYPE.SLIDE_START: {
            videoSchedule.registerAction(timeKey, () => {
              setSlideData(slideId, eventData)
            })
            break
          }
          case EVENT_TYPE.SLIDE_END: {
            videoSchedule.registerAction(timeKey, () => {
              setSlideData(slideId, eventData)
              const { hasInteractivity, hasLinks } = slidesMetaHashMap[slideId]
              if (hasInteractivity) controlSetToHoverHandler(true)
              if (!isNavigationAllowed && hasInteractivity && !hasLinks) playDisabledHandler(true)
              if (nextNavigationSlide) rewindOnSlideHandler(nextNavigationSlide, true)
            })
            break
          }
          default: {
            break
          }
        }
      })
    })
    setIsActionsScheduled(true)
  }, [videoSchedule, forceChangedTime])

  // Effect to softly modify slideEvent object to trigger useEffect for Interactivity end handler in Slide.jsx
  // Case when slide end triggered but after moving time back by timeline, another pause didn't trigger on slide end.
  useEffect(() => {
    if (!currentSlideId || !isPaused) return
    setSlideData(currentSlideId, { ...slideEvent, rand: Math.random() })
  }, [forceChangedTime])

  useEffect(() => {
    if (!videoSchedule) return
    if (!analyticCollector) return
    if (!videoElement) return
    analyticCollector.init({ videoSchedule, videoElement, studentEmail: email, studentName: name })
    if (videoSchedule.hasVideoQuizes) analyticCollector.setHasQuiz()
  }, [videoSchedule, videoElement, name, email, analyticCollector])

  useEffect(() => {
    if (!analyticCollector) return
    if (!analyticCollector.isInitialized) return
    if (isPaused) {
      analyticCollector.stopTimer()
    } else {
      analyticCollector.startTimer()
    }
  }, [isPaused, analyticCollector])

  useEffect(() => {
    // start watcher
    if (!videoElement) return
    if (!isVideoScheduleReady) return
    if (!isActionsScheduled) return
    if (!videoSchedule.hasVideoInteractivity) return

    const findSlideId = (schedule, time) => {
      const slideIdByTime = schedule.getSlideIdByTime(time)
      if (currentSlideDataRef.current.slideId === slideIdByTime) return
      setSlideData(slideIdByTime)
    }

    const rafCallback = createRAFCallback(videoElement, videoSchedule, findSlideId)
    window.requestAnimationFrame(rafCallback)
  }, [isActionsScheduled])

  useEffect(() => {
    if (!analyticCollector) return
    analyticCollector.setCurrentSlideId(currentSlideId, isPaused)
  }, [analyticCollector, currentSlideId])

  return (
    <ScheduledPlayerContextProvider
      rewindOnSlideHandler={rewindOnSlideHandler}
      rewindToPrevSlideHandler={rewindToPrevSlideHandler}
      rewindToNextSlideHandler={rewindToNextSlideHandler}
    >
      <InteractivePlayerView
        video={children}
        controls={<VideoControls />}
        spinner={
          !isVideoReady ? (
            <div className="player-root--spinner">
              <Spin />
            </div>
          ) : null
        }
        playPauseOverlay={
          <PlayPauseOverlay isPaused={isPaused} videoElement={videoElement} bounding={playerBounding} />
        }
        interactiveObjects={
          <AnimatePresence>
            <Slide key={currentSlideId} slideEvent={slideEvent} slideMeta={currentSlideMeta} />
          </AnimatePresence>
        }
      />
    </ScheduledPlayerContextProvider>
  )
}
